import {useCallback, useMemo, useState} from 'react'

export default function useJobOffers(apiKey) {
  const [jobRecordCount, setJobRecordCount] = useState(null)
  const [error, setError] = useState(null)

  const jobRequestOptions = useCallback(
    key => ({
      method: 'GET',
      headers: {
        Authorization: `Token token=${key}`,
        'X-Api-Version': '20210218',
        'Content-Type': 'application/json',
      },
    }),
    [],
  )

  const fetchData = useCallback(async () => {
    try {
      const requestUrl = 'https://api.teamtailor.com/v1/'
      const jobOffersEndpoint = 'jobs'

      const response = await fetch(
        `${requestUrl}${jobOffersEndpoint}`,
        jobRequestOptions(apiKey),
      )

      if (!response.ok) {
        throw new Error(`Error: ${response.status}, ${response.statusText}`)
      }

      const data = await response.json()

      setJobRecordCount(data.meta['record-count'])
    } catch (err) {
      setError(`Error: ${err.message}`)
    }
  }, [apiKey, jobRequestOptions])

  useMemo(() => {
    fetchData()
  }, [fetchData])

  return {jobRecordCount, error}
}
